
import React, { useState, useCallback } from 'react';
import { ArrowLeftIcon, PhotoIcon } from '@heroicons/react/24/outline';
import { VideoCameraSlashIcon } from '@heroicons/react/24/solid';
import { VideoCameraIcon } from '@heroicons/react/24/solid';
import { FaYoutube } from 'react-icons/fa';
import { useFormContext } from "react-hook-form";
import { IoIosVideocam } from 'react-icons/io';


const IllustrationPreview = ({ activeInput }) => {

    const extractYoutubeId = (source) => {
        const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = source.match(regex);  // Match the YouTube URL pattern
        if (!match) return null; // Return null if no match is found
        // Return the YouTube embed URL using the matched ID
        // return `https://www.youtube.com/embed/${match[1]}`;

        let url = `https://www.youtube.com/embed/${match[1]}`;

        return url.replace(/&t=\d+/, ''); // Remove the timestamp parameter if it exists    

    }


    if (!activeInput) return null; // Handle case when activeEvent is null

    const { detectedType, value } = activeInput;

    return (
        <div>

            {['video_url', 'contribution'].includes(activeInput.name)
                && activeInput.value && <div>
                    <iframe src={extractYoutubeId(activeInput.value)} className="w-full"></iframe>
                </div>}


            {['presta_visuel', 'file_src'].includes(activeInput.name)
                && detectedType
                && detectedType.startsWith('image/')
                && activeInput.value && <div>
                    <img src={(value)} alt="Preview" className="w-100 h-auto" />
                </div>
            }

            {['video_hosted', 'file_src'].includes(activeInput.name)
                && detectedType
                && (detectedType === 'video/mp4' || detectedType === 'video/quicktime') && activeInput.value && <div>
                    <video controls className="w-100 h-auto">
                        <source src={value} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            }


        </div>

    )

}

const PrestaIllustrationSection = ({ handleInputChange,
    inputVideoUrlName = "contribution",
    inputVideoHostedName = "file_src",
    inputPrestaVisuelName = "file_src" }) => {


    const { register } = useFormContext();

    const [illustrationTypeSelected, setIllustrationTypeSelected] = useState(null);

    const [videoTypeSelected, setVideoTypeSelected] = useState(null);

    const [activeInput, setActiveInput] = useState({ name: null, value: null, type: null, detectedType: null });
    const [fileName, setFileName] = useState(null);


    const handleChange = (e) => {

        const { name, type, files, value } = e.target;

        let previewValue = value;
        let detectedType = null;

        if (type === 'file' && files && files.length > 0) {
            // Create a preview URL from the first selected file

            const file = files[0];
            previewValue = URL.createObjectURL(files[0]);
            detectedType = file.type;  // e.g., "image/png" or "video/mp4"
        }

        // Notify parent (if needed)
        handleInputChange(e);

        // Update local state for preview display
        setActiveInput({ name, value: previewValue, type, detectedType });

    }



    const handleSelectIllustrationType = useCallback((type) => (e) => {
        e.preventDefault();
        setIllustrationTypeSelected(type);
    }, []);

    const handleVideoTypeSelect = useCallback((type) => (e) => {
        e.preventDefault();
        setVideoTypeSelected(type);
    }, []);

    const handleBack = useCallback((e) => {
        e.preventDefault();
        setIllustrationTypeSelected(null);
        setVideoTypeSelected(null); // Reset video type as well
    }, []);

    return (
        <div className="flex flex-col space-y-3 pb-4 rounded-md ">
            {illustrationTypeSelected ? (
                <button
                    style={{ borderWidth: '2px' }}
                    onClick={handleBack} className="flex w-fit p-2 bg-neutral-50 rounded text-black shadow-none border-2  border-x border-y border-neutral-400 text-xs"><ArrowLeftIcon className="w-4" /> Retour</button>
            ) : (
                <div className="grid grid-cols-2 mb-3 gap-2">
                    <button
                        style={{ borderWidth: '2px' }}
                        className="text-sm  rounded-lg border-neutral-200 bg-neutral-50 text-black border-2 flex flex-col justify-center items-center font-normal hover:bg-neutral-200 shadow-none cursor-pointer p-4"
                        onClick={handleSelectIllustrationType('video')}
                    >
                        <VideoCameraIcon className="w-10 h-10 " />

                        <span className="block md:hidden">Je prends une vidéo</span>
                        <span className="hidden md:block">Je charge une vidéo au format <i>.mp4</i> ou <i>.mov </i></span>

                    </button>
                    <button
                        style={{ borderWidth: '2px' }}
                        className="hidden group:hover:bg-pink-200 rounded-lg border-neutral-200 bg-neutral-50 text-black border-2 flex flex-col justify-center items-center font-normal hover:bg-neutral-200 shadow-none cursor-pointer p-4"
                        onClick={handleSelectIllustrationType('image')}
                    >
                        <PhotoIcon className="h-10 w-10 text-neutral-600" />
                        Je prends ou charge une image
                    </button>
                    <label className="text-sm rounded-lg border-neutral-200 bg-neutral-50 text-black border-2 flex flex-col justify-center items-center font-normal hover:bg-neutral-200 shadow-none cursor-pointer p-4">

                        <PhotoIcon className="h-10 w-10 text-neutral-400" />
                        <span className="block md:hidden">Je prends une photo</span>
                        <span className="hidden md:block">Je charge une image au format <i>.jpeg</i> ou <i>.png</i></span>
                        <input
                            {...register(inputPrestaVisuelName)}
                            className="sr-only mt-3 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            type="file"
                            name={inputPrestaVisuelName}
                            onChange={handleChange}
                            accept="image/png, image/jpeg"
                        />
                    </label>






                </div>
            )}

            {illustrationTypeSelected === 'image' && (
                <div>
                    <label className="flex flex-col">
                        <span className="text-gray-700 font-normal text-sm">Chargez votre image d'illustration (PNG ou JPG uniqumement)  :</span>
                        <input
                            {...register(inputPrestaVisuelName)}
                            className="mt-3 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            type="file"
                            name={inputPrestaVisuelName}

                            accept="image/png, image/jpeg"
                            onChange={handleChange}
                        />
                    </label>
                </div>
            )}

            {illustrationTypeSelected === 'video' && (
                <div>
                    <div className="flex space-x-2 mb-3">
                        <label className=" w-1/2 text-sm rounded-lg border-neutral-200 bg-neutral-50 text-black border-2 flex flex-col justify-center items-center font-normal hover:bg-neutral-200 shadow-none cursor-pointer p-4"   >
                            <VideoCameraIcon className="h-10 w-10" />
                            Je prends ou charge une vidéo
                            <input
                                {...register(inputVideoHostedName)}
                                className="sr-only mt-1  w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                type="file"
                                name={inputVideoHostedName}
                                onChange={handleChange}
                                accept="video/mp4"

                            />
                        </label>
                        <button
                            className={`p-4 w-1/2 flex flex-col items-center  hover:bg-neutral-200  rounded-lg  px-2 font-normal text-sm text-black  border-x border-y  border-neutral-200 ${videoTypeSelected === 'link' ? 'bg-neutral-300 ' : 'bg-neutral-50 '}`}
                            onClick={handleVideoTypeSelect('link')}
                        >

                            <FaYoutube className="h-10 w-10 text-neutral-400" />




                            Je copie/colle un lien youtube
                        </button>
                        <button
                            className={`hidden hover:bg-neutral-200 rounded-lg   px-2 font-normal text-sm text-black border-x border-y  border-neutral-200  ${videoTypeSelected === 'upload' ? 'bg-neutral-300 ' : 'bg-neutral-50 '}`}
                            onClick={handleVideoTypeSelect('upload')}
                        >
                            Je prends une vidéo ou la télécharge au format mp4
                        </button>

                    </div>

                    {videoTypeSelected === 'upload' && (
                        <label className="block">
                            <span className="text-gray-700 font-bold">Chargement de la vidéo :</span>
                            <input
                                {...register(inputVideoHostedName)}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                type="file"
                                name={inputVideoHostedName}
                                onChange={handleChange}
                                accept="video/mp4"
                            />
                        </label>
                    )}

                    {videoTypeSelected === 'link' && (
                        <label className="block">
                            <span className="text-gray-700 font-bold">Lien youtube :</span>
                            <input
                                {...register(inputVideoUrlName)}
                                className="p-2 border mt-1 block w-full rounded-full border-gray-700 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                type="text"
                                name={inputVideoUrlName}
                                placeholder="e.g., URL"
                                onChange={handleChange}
                            />
                        </label>
                    )}
                </div>
            )}
            <IllustrationPreview activeInput={activeInput} />
        </div>
    );
};


export default PrestaIllustrationSection;