import React, { useContext, useEffect, useState } from 'react';

import RegistrationFormRow from './RegistrationFormRow'
import RegistrationFormSection from './RegistrationFormSection';
import { useTranslation, Trans } from 'react-i18next';
import RegistrationFormCountry from './RegistrationFormCountry';
import EventContext from '../contexts/EventContext';
import OrganismeVideoUrlChecker from './OrganismeVideoUrlChecker';
import OrganismeWebSiteChecker from './OrganismeWebSiteChecker';
import PhoneNumberInput from './PhoneNumberInput';
import HTMLReactParser from 'html-react-parser';
import { use } from 'i18next';
import { useParams } from 'react-router-dom';
import { RegistrationFormSupertitleSection } from './RegistrationFormStyleSection';
import ContactShow from './ContactShow';


const FORM_SECTION_ID = 5;

const RegistrationFormContactAndOrganismeLightSection = ({ formulaire, contact }) => {

    const { t, i18n } = useTranslation();

    const eventContext = useContext(EventContext);

    const { idEvent } = useParams();

    const { currentEvent } = eventContext;

    const [showEditSection, setShowEditSection] = useState(true);


    const handleEditButton = (e) => {
        e.preventDefault()

        setShowEditSection(!showEditSection);
        console.log("handleEditButton")
    }



    let instructions;

    if (formulaire && formulaire.id_formulaire == 44 && idEvent == 2078) {

        instructions = `On-Screen Information Entry Area
This area allows you to input the details that will be displayed on screen during your
webcast or presentation, and that will also be accessible when participants scan the
provided QR code.Please review and fill in the fields below to ensure accuracy in the
information displayed(Company / Startup Name.Primary Contact Information / Website
etc.)`;

    } else {
        instructions = HTMLReactParser(formulaire.contact_and_organisme_section_instructions ?? "")
    }

    useEffect(() => {

        if (contact) {

            setShowEditSection(true);
        } else {
            setShowEditSection(true);
        }


    }, [i18n.resolvedLanguage, contact, formulaire]);


    return <RegistrationFormSection
        formSectionVisuel={formulaire.contact_and_organisme_section_visuel_thumbs?.medium}
        formSectionId={FORM_SECTION_ID}
        supertitle={<RegistrationFormSupertitleSection>{formulaire.contact_and_organisme_section_supertitle}</RegistrationFormSupertitleSection>}
        title={formulaire.contact_and_organisme_section_title ? formulaire.contact_and_organisme_section_title : t('form.sections.contact_and_organisation_section.section_title')}
        instructions={instructions}
        title_={t('form.sections.contact_and_organisation_section.section_title')} >

        <div className='flex justify-end'>
            <button
                onClick={handleEditButton}
                className=" border-2 bg-blue-200 rounded-full w-28 text-black border-blue-600 ">
                {showEditSection ? t('form.voir_appercu') : t('form.modifier')}
            </button>
        </div>

        {!showEditSection ? <ContactShow

            setShowEditSection={setShowEditSection}
            contact={contact} /> : <> <RegistrationFormRow
                name='prenom'
                placeholder={formulaire && formulaire.id_formulaire == 44 && idEvent == 2078 ? "First name" : t('form.sections.contact_section.prenom')}
                defaultValue={contact?.prenom} />

            <RegistrationFormRow
                name='nom'
                placeholder={formulaire && formulaire.id_formulaire == 44 && idEvent == 2078 ? "Last name" : t('form.sections.contact_section.nom')}
                defaultValue={contact?.nom} />

            {/* <RegistrationFormRow
                name='fonction'
                required={false}
                placeholder={formulaire && formulaire.id_formulaire == 44 && idEvent == 2078 ? "Job Title" : t('form.sections.contact_section.fonction')}
                defaultValue={contact?.fonction}
            /> */}



            {/* <PhoneNumberInput /> */}

            <RegistrationFormRow
                name='port'
                placeholder="Mobile"
                defaultValue={contact?.port}
            />
            <RegistrationFormRow
                name='mail'
                placeholder="E-mail"
                defaultValue={contact?.mail} />

            <RegistrationFormRow
                name='societe'
                placeholder={formulaire && formulaire.id_formulaire == 44 && idEvent == 2078 ? "Startup name" : t('form.sections.organisme_section.societe')}
                defaultValue={contact?.societe} />
            {/* 
        <RegistrationFormRow
            isRegister
            inputType="textarea"
            name='edito_long'
            placeholder={t('form.sections.organisme_section.edito_long')}
            required={false}
            defaultValue={contact?.edito_long} />

             
     
            <RegistrationFormCountry
                formulaire={formulaire}
                contact={contact}
                required={true} />

            <RegistrationFormRow
                inputType="file"
                name='photo'
                placeholder={t('form.sections.contact_section.photo')}
                defaultValue={contact?.photos.large}
                required={contact?.photos.large != '' ? false : true}
                accept={['.jpeg', '.png', '.jpg']}
                helper="Important  : Cette photo sera restituée lors de l'enregistrement de votre webcast" />

            <RegistrationFormRow
                inputType="file"
                name='logo'
                placeholder={formulaire && formulaire.id_formulaire == 44 && idEvent == 2078 ? "Important : Logo of my company" : t('form.sections.organisme_section.logo')}
                defaultValue={contact?.logos.large}
                required={contact?.logos.large != '' ? false : true}
                accept={['.jpeg', '.png', '.jpg']} />

            <RegistrationFormRow
                required={true}
                inputType="text"
                name='web'
                placeholder={t('form.sections.organisme_section.web')}
                defaultValue={contact?.web}
                helper={t('form.sections.organisme_section.web_helper')}>
                <OrganismeWebSiteChecker />

            </RegistrationFormRow>   */}

        </>}






    </RegistrationFormSection >
}

export default RegistrationFormContactAndOrganismeLightSection;
